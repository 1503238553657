import { Controller, useForm } from "react-hook-form"
import { type FC, useContext } from "react"
import { Button } from "@/components/ui/Button/Button"
import { ModalContext } from "@/components/ui/Modal"
import { StyledForm } from "@/forms/StyledForms"
import { FAVORITES_DEFAULT_TAG_NAME, useFavorites } from "@/hooks/favorites"
import { Field } from "@/ui/Field/Field"
import { getTrimmingValue } from "@/utils/common/helpers"
import { RULE_REQUIRED } from "@/validations/phone"

type FormValues = {
  name: string
}

type CreateListFormPropsType = {
  onSuccess?: () => void
}

export const CreateListForm: FC<CreateListFormPropsType> = ({ onSuccess }) => {
  const modalContext = useContext(ModalContext)
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: "",
    },
    mode: "onChange",
  })
  const { addGroup } = useFavorites()

  const onValid = ({ name }: FormValues) => {
    const trimmingValue = getTrimmingValue(name)
    addGroup(trimmingValue)

    modalContext?.hide()

    if (typeof onSuccess === "function") {
      onSuccess()
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit(onValid)}>
      <Controller
        name="name"
        control={control}
        rules={{
          ...RULE_REQUIRED,
          validate: (value: string) => {
            const trimmingValue = getTrimmingValue(value)
            if (!trimmingValue) {
              return RULE_REQUIRED.required.message
            }

            if (trimmingValue === FAVORITES_DEFAULT_TAG_NAME) {
              return "Недопустимое название"
            }

            return true
          },
        }}
        render={({ field, fieldState }) => (
          <Field
            withAnimatingLabel
            placeholder="Название"
            type={"text"}
            errorMessage={fieldState.error?.message}
            value={field.value}
            onChange={field.onChange}
            ref={field.ref}
          />
        )}
      />
      <div>
        <Button type="submit" variant="filled">
          Сохранить
        </Button>
      </div>
    </StyledForm>
  )
}

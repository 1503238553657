import {
  _unitsSortDesc,
  DEFAULT_QTY,
  getCurrentSpecification,
  getUnits,
  initialState,
} from "./helpers"
import { EntityStoreType, ProductActionTypes, ReducerStateType } from "./types"
import { getAvailableStatus } from "components/Available"

export const reducer: ReducerStateType = (state, action) => {
  switch (action.type) {
    case ProductActionTypes.setCount: {
      const isLog = !!action.payload.isLog
      let currentUnit = state.unit
      const totalQty = state.totalQty

      if (isLog) {
        console.log("[setCount] currentUnit ", currentUnit)
      }

      const _validate = (q: number | null): number | null => {
        const units = state.units
        const isInit = state.isInit
        const withError = !!action.payload.witError
        let count: number | null = q

        if (units === null) {
          return null
        }

        if (count === null) {
          return currentUnit
        }

        const unitOne = units.find((u) => +u.value === DEFAULT_QTY)?.value

        if (currentUnit === null || count % currentUnit !== 0) {
          const rightUnit = (state.units || []).find(
            (u) => (count || 0) % +u.value === 0,
          )
          const val =
            rightUnit === undefined ? +units[0].value : +rightUnit.value

          if (isLog) {
            console.log("[setCount] [currentUnit is null] units ", units)
            console.log("[setCount] [currentUnit is null] isInit ", isInit)
            console.log("[setCount] [currentUnit is null] rightUnit ", val)
          }

          currentUnit = val
        }

        if (isLog) {
          console.log(
            "[updateCounter _validate] count ",
            count,
            " isInit ",
            isInit,
          )
        }

        const isMoreTotal = count > totalQty
        const isLessUnit = count < currentUnit

        if (isMoreTotal || isLessUnit) {
          if (isMoreTotal) {
            if (isInit && withError) {
              if (isLog) {
                console.log("[updateCounter _validate] isMoreTotal")
              }
              state.isCountError = true
            }
            count = _validate(totalQty)
          }
          if (isLessUnit) {
            if (currentUnit > totalQty) {
              if (isLog) {
                console.log("[updateCounter _validate] isLessUnit")
              }

              //sort desc
              const uVal = _unitsSortDesc(units).find(
                (u) => (count || 0) >= +u.value,
              )?.value

              if (uVal !== undefined) {
                if (isLog) {
                  console.log(
                    "[updateCounter _validate] isLessUnit updateUnit ",
                    +uVal,
                  )
                }

                // такой себе forceUpdate
                // ставим -1, тк при ситуации когда unit был 1
                // и задиспатчится здесь также 1 (начальное значение)
                // переключатель не среагирует, тк не было изменений
                // в таком случае он переключится на начальное значение, тк -1 это новое значение
                currentUnit = +uVal === 1 ? -1 : +uVal
              } else {
                // когда введенное значение меньше всех юнитов в которых исчисляется товар
                // т.е. в наличии кол-во товара меньше чем юниты в которых он исчисляется
                // оставляем максимально допустимое значение
                count = totalQty
              }
            } else {
              if (unitOne !== undefined && withError) {
                if (isLog) {
                  console.log("[updateCounter _validate] unitOne ", +unitOne)
                }
                currentUnit = +unitOne
                count = +unitOne
              } else {
                count = currentUnit
              }
            }
          }
        } else {
          if (count % currentUnit !== 0) {
            if (unitOne !== undefined) {
              currentUnit = +unitOne
            } else {
              let variant = currentUnit * Math.ceil(count / currentUnit)

              if (variant > totalQty) {
                if (isInit && withError) {
                  state.isCountError = true
                }
                variant = currentUnit * Math.floor(count / currentUnit)
              }
              count = variant
            }
          } else {
            count = currentUnit * Math.floor(count / currentUnit)
          }
        }

        return count === null ? currentUnit : count
      }

      const validated = _validate(action.payload.count)

      if (isLog) {
        console.log("[setCount] return ", {
          ...state,
          unit: currentUnit,
          count: validated,
        })
      }

      return {
        ...state,
        count: validated,
        unit: currentUnit,
      }
    }
    case ProductActionTypes.setCounter: {
      return {
        ...state,
        counter: action.payload.counter,
      }
    }
    case ProductActionTypes.setUnits: {
      const { unitMeasure, isOnlyContainers, initialUnits, parentKit } =
        action.payload
      const { units } = getUnits({
        units: initialUnits,
        isOnlyContainers: isOnlyContainers,
        unitMeasure: unitMeasure,
        parentKit: parentKit,
      })
      return {
        ...state,
        units: units,
      }
    }
    case ProductActionTypes.setUnit: {
      return {
        ...state,
        unit: action.payload.unit,
      }
    }
    case ProductActionTypes.setIsInit: {
      return {
        ...state,
        isInit: action.payload,
      }
    }
    case ProductActionTypes.setInCart: {
      return {
        ...state,
        inCart: action.payload,
      }
    }
    case ProductActionTypes.setCurrentSpecification: {
      let spec = null

      if (
        action.payload !== null &&
        action.payload.specification !== null &&
        action.payload.uuid !== null
      ) {
        spec = getCurrentSpecification({
          specification: action.payload.specification,
          uuid: action.payload.uuid,
          parent: action.payload.parentKit,
        })
      }

      const qty = spec?.quantity !== undefined ? spec?.quantity || 0 : null

      return {
        ...state,
        currentSpecification: spec,
        sampleCount: !!spec?.isSampleRemoved ? 0 : spec?.sample || 0,
        isRemoved: !!spec?.isRemoved,
        isAnimate: spec?.isAnimate === undefined ? null : !!spec?.isAnimate,
        inCart: qty !== null && qty > 0,
      }
    }
    case ProductActionTypes.setTotalQty: {
      return {
        ...state,
        totalQty:
          action.payload.total -
          (action.payload?.sample || state.sampleCount || 0),
      }
    }
    case ProductActionTypes.setIsCountError: {
      return {
        ...state,
        isCountError: action.payload,
      }
    }
    case ProductActionTypes.reset: {
      return {
        ...initialState,
      }
    }
    case ProductActionTypes.resetOnRoute: {
      return {
        ...state,
        counter: null,
        count: null,
        currentSpecification: null,
        units: null,
        unit: null,
      }
    }
    case ProductActionTypes.setStores: {
      const { stores = [], maxQty, minQty } = action.payload
      if (stores.length === 0) {
        return {
          ...state,
          stores: [],
          storesAvailable: [],
        }
      }
      const sortedStores = [...stores]
        .sort((x, y) => (x.is_main === y.is_main ? 0 : x.is_main ? -1 : 1))
        .map(({ uuid, quantity = 0, name, is_main = false }) => {
          const status = getAvailableStatus({
            quantity: quantity,
            maxQty: maxQty,
            minQty: minQty,
          })

          return {
            uuid: uuid,
            quantity: quantity,
            name: name,
            isMain: is_main,
            availableStatus: status,
          } as EntityStoreType
        })

      return {
        ...state,
        stores: sortedStores,
      }
    }
    default: {
      return state
    }
  }
}

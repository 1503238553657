import { StyledPopoverContent } from "@/components/Products";
import { ButtonAddToFavorites } from "@/components/Products/parts/ButtonAddToFavorites";
import { ButtonSizeAlias } from "@/components/ui/Button/constants";
import { useFavorites } from "@/hooks/favorites";
import { useAppSelector } from "@/hooks/redux";
import { cssIsActive } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { cssButtonProductToggle } from "@/ui/Button/StyledButton";
import { ButtonVariantsPropsType } from "@/ui/Button/types";
import { Popover } from "@/ui/Popover/Popover";
import { gtagSend } from "@/utils/common/helpers";
import { GTAG_EVENTS } from "@/utils/constants";
import { cx } from "@linaria/core";
import { type FC, memo, type MouseEvent, useCallback, useState } from "react";
const TEXT_NOT_ACTIVE = "Добавить в избранное";
const TEXT_ACTIVE = "Удалить из избранного";
export const ButtonToggleFavorite: FC<{
  productUUID: string;
  isFavorites: boolean;
  isFetching?: boolean;
  isDetail?: boolean;
  isProductCard?: boolean;
  groupId?: string | null;
} & Pick<ButtonVariantsPropsType, "size" | "variant" | "isClean">> = memo(({
  productUUID,
  isFavorites,
  isFetching = false,
  size = ButtonSizeAlias.SMALL,
  variant = "box",
  isClean,
  groupId
}) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const text = isFavorites ? TEXT_ACTIVE : TEXT_NOT_ACTIVE;
  const isAuth = useAppSelector(state => state.profile.isAuth);
  const {
    add,
    remove
  } = useFavorites();
  const toggleHandle = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isFavorites) {
      setIsPopoverVisible(false);
      remove(productUUID, groupId || undefined);
    } else {
      gtagSend({
        event: GTAG_EVENTS.ADD_TO_FAVORITES
      });
      setIsPopoverVisible(true);
      add([productUUID], groupId || undefined);
    }
  }, [isFavorites, productUUID, groupId, add, remove]);
  const buttonElement = <Button variant={!!variant ? variant : "link"} icon={isFavorites ? "HeartFilled" : "Heart"} className={cx(cssButtonProductToggle, isFavorites && cssIsActive)} onClick={toggleHandle} isFetching={isFetching} size={size} aria-label={text} role={"button"} type={"button"} title={text} isClean={isClean} />;
  if (isAuth) {
    return <Popover isShow={isPopoverVisible} isStylingIconDisclosure={false} placement={"bottom-start"} size="default" disclosure={buttonElement} style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
          <StyledPopoverContent>
            <ButtonAddToFavorites productUUID={productUUID} />
          </StyledPopoverContent>
        </Popover>;
  }
  return buttonElement;
});
ButtonToggleFavorite.displayName = "ButtonToggleFavorite";
import { type FC, useCallback, useContext } from "react"
import { CreateListForm } from "@/components/Favorites/CreateListForm"
import { TagsSlider } from "@/components/Tags/TagsSlider"
import { TagDescriptor } from "@/components/Tags/types"
import { Button } from "@/components/ui/Button/Button"
import { FAVORITES_DEFAULT_TAG_NAME, useFavorites } from "@/hooks/favorites"
import { useAppSelector } from "@/hooks/redux"
import { Modal, ModalContext } from "@/ui/Modal"
import { ButtonContainer } from "./StyledAddToListForm"

type AddToListFormPropsTypes = {
  productUUID: string
}

export const AddToListForm: FC<AddToListFormPropsTypes> = ({ productUUID }) => {
  const modalContext = useContext(ModalContext)
  const { groups, groupsProductsIds } = useAppSelector(
    (state) => state.favorites,
  )
  const { add } = useFavorites()

  const getIsSelectedGroup = (groupId: string) => {
    const productIdsGroup = groupsProductsIds[groupId] || []
    return productIdsGroup.length >= 0 && productIdsGroup.includes(productUUID)
  }

  const tags: TagDescriptor[] = (groups || [])
    .filter(({ name }) => name !== FAVORITES_DEFAULT_TAG_NAME)
    .map(({ uid, name, quantity }) => {
      const selected = getIsSelectedGroup(uid)

      return {
        id: uid,
        name,
        qty: quantity,
        selected: selected,
      }
    })
    .sort(({ selected: selectedA }, { selected: selectedB }) =>
      selectedA === selectedB ? 0 : selectedA ? 1 : -1,
    )

  const onSelectTag = useCallback(
    (groupId: string | number) => {
      const selected = getIsSelectedGroup(`${groupId}`)
      if (selected) {
        return
      }

      add([productUUID], `${groupId}`)

      modalContext?.hide()
    },
    [add, productUUID],
  )

  return (
    <div>
      {tags.length > 0 && (
        <TagsSlider
          canBeRemoved={false}
          tags={tags}
          selectedCategory={null}
          onSelectTag={onSelectTag}
        />
      )}

      <ButtonContainer>
        <Modal
          variant={"rounded-50"}
          title={"Создать список"}
          closeMode={"destroy"}
          disclosure={
            <Button variant="link" icon="PlusCircle">
              Создать список
            </Button>
          }
        >
          <CreateListForm />
        </Modal>
      </ButtonContainer>
    </div>
  )
}

import { AvailableStatus } from "@/components/Available"
import {
  ProductType,
  ProductVariationType,
  ProductWithChildType,
} from "@/components/Products/types"
import {
  ActionMapType,
  ChangeQtyProductResponse,
  PropertiesType,
  RadioGroupItemsType,
  SpecificationItemType,
} from "@/types"
import { RuleSortProduct } from "@/utils/common/helpers"
import { initialState } from "./helpers"
import { ApiError, ChangeQtyRequest } from "../../../contracts"

type ProductStateType = typeof initialState

type AddToCartOptionsType = {
  onSuccess?: (
    response: ChangeQtyProductResponse,
    variables: ChangeQtyRequest,
  ) => void
  onError?: (error: ApiError | unknown) => void
  onMutate?: () => void
}

type UseProductPropsType = {
  product: ProductWithChildType
  options?: {
    parentKit?: string
    isSaveOnRemove?: boolean
    onAddToCart?: AddToCartOptionsType
    initQty?: number
    withLocation?: boolean
    isDetail?: boolean
  }
}

type StoreType = {
  uuid: string
  name?: string
  quantity?: number
  is_main?: boolean
  pickup?: boolean
  delivery?: boolean
}

type EntityStoreType = Omit<StoreType, "is_main"> & {
  isMain?: boolean
  availableStatus?: AvailableStatus
  deliveryDate?: Date
}

type UseProductReturnType = {
  alias: string | null
  name: string | null
  isFavorites: boolean
  availableStatus: AvailableStatus | null
  units: RadioGroupItemsType[]
  totalQty: number
  totalQtyBase: number
  currentCount: number | null
  counter: number | null
  currentUnit: number | null
  priceUnit: number
  priceCalculate: number
  isFetching: boolean
  isFetchingFavorites: boolean
  inCart: boolean
  isRemoved: boolean
  isAvailable: boolean
  child?: Record<string, ProductWithChildType>
  properties: PropertiesType
  kit: string[]
  kitParents: string[]
  stores: EntityStoreType[]
  storesAvailable: EntityStoreType[]
  storesQty: number
  categories: string[]
  description: string | null
  fastQty: number
  images: string[]
  isAnimate: boolean | null
  isKit: boolean
  isBestseller: boolean
  isNew: boolean
  setIsFetching: (val: boolean) => void
  setInCart: (val: boolean) => void
  updateCurrentCount: (value: number) => void
  updateCurrentUnit: (value: number) => void
  addToCart: () => void
  removeFromCart: () => void
  isCopiedPath: boolean
  copyPath: () => void
  isCopiedCode: boolean
  code: string | null
  copyCode: () => void
  copyArticle: () => void
  isCopiedArticle: boolean
  share: () => void
  isShowShare: boolean
  setIsShowShare: (val: boolean) => void
  sharePath: string | null
  isCountError: boolean
  setIsCountError: (isError: boolean) => void
  isInit: boolean
  isAdded: boolean
  setIsAdded: (value: boolean) => void
  currentSpecification: SpecificationItemType | null
  isAllowSample: boolean
  analogs: RuleSortProduct[]
  analogsIds: string[]
  updateDiscount: (discount: number) => void
  isCompares: boolean
  toggleCompares: () => void
  // isShowToLogin: boolean
  // hideToLogin: () => void
  isVariate: boolean
  variations: ProductVariationType[]
  uuid: string | null
  path: string | null
  unitMeasure: string | null
  article: string | null
} & Pick<ProductType, "params">

type UseProductType = (props: UseProductPropsType) => UseProductReturnType

type GetUnitsType = ({
  units,
  isOnlyContainers,
  unitMeasure,
  parentKit,
}: {
  units: { name?: string; value?: number }[] | null
  isOnlyContainers: boolean
  unitMeasure: string | undefined | null
  parentKit?: string
}) => { units: null | RadioGroupItemsType[] }

export enum ProductActionTypes {
  setProduct = "SET_PRODUCT",
  setCount = "SET_COUNT",
  setCounter = "SET_COUNTER",
  setUnit = "SET_UNIT",
  setIsInit = "SET_IS_INIT",
  setUnits = "SET_INITS",
  setInCart = "SET_IN_CART",
  setCurrentSpecification = "SET_CURRENT_SPECIFICATION",
  setTotalQty = "SET_TOTAL_QTY",
  setIsRemoved = "SET_IS_REMOVED",
  setIsAnimate = "SET_IS_ANIMATE",
  setIsCountError = "SET_IS_COUNT_ERROR",
  reset = "RESET",
  resetOnRoute = "RESET_ON_ROUTE",
  setStores = "SET_STORES",
}

type ProductPayload = {
  [ProductActionTypes.setProduct]: {
    product: ProductWithChildType | null
    parentKit?: string
  }
  [ProductActionTypes.setCount]: {
    count: number | null
    witError?: boolean
    isLog?: boolean
    parentKit?: string
  }
  [ProductActionTypes.setCounter]: {
    counter: number | null
  }
  [ProductActionTypes.setIsInit]: boolean
  [ProductActionTypes.setUnits]: {
    initialUnits: { name?: string; value?: number }[]
    isOnlyContainers: boolean
    parentKit?: string
    unitMeasure: string | null
  }
  [ProductActionTypes.setUnit]: {
    unit: null | number
  }
  [ProductActionTypes.setInCart]: boolean
  [ProductActionTypes.setCurrentSpecification]: {
    specification: Record<string, SpecificationItemType> | null
    uuid: string | null
    parentKit?: string
    isLog?: boolean
  } | null
  [ProductActionTypes.setTotalQty]: { total: number; sample?: number }
  [ProductActionTypes.setIsRemoved]: boolean
  [ProductActionTypes.setIsAnimate]: boolean
  [ProductActionTypes.setIsCountError]: boolean
  [ProductActionTypes.reset]: undefined
  [ProductActionTypes.resetOnRoute]: undefined
  [ProductActionTypes.setStores]: Pick<ProductType, "stores"> & {
    minQty: number
    maxQty: number
  }
}

type ProductActions =
  ActionMapType<ProductPayload>[keyof ActionMapType<ProductPayload>]

type ReducerStateType = (
  state: ProductStateType,
  action: ProductActions,
) => ProductStateType

export type {
  ProductStateType,
  AddToCartOptionsType,
  UseProductPropsType,
  UseProductReturnType,
  UseProductType,
  GetUnitsType,
  ProductPayload,
  ProductActions,
  ReducerStateType,
  StoreType,
  EntityStoreType,
}

import { cx } from "@linaria/core";
import { BaseHTMLAttributes, FC } from "react";
import { cssTag, cssTagControl, cssTagDeleteButton } from "@/components/Tags/StyledTags";
import { Button } from "@/ui/Button/Button";
export type TagControlPropsType = BaseHTMLAttributes<HTMLDivElement> & {
  name: string;
  uuid: string | number;
  qty?: number;
  canBeRemoved?: boolean;
  onSelectTag: (uuid: string | number) => void;
  onClearTag?: (uuid: string | number) => void;
};
const TagControl: FC<TagControlPropsType> = ({
  name,
  uuid,
  qty,
  canBeRemoved = true,
  className,
  onSelectTag,
  onClearTag,
  ...props
}) => {
  return <div className={cx(cssTag, cssTagControl, className)} data-id={uuid} {...props}>
      <Button isClean onClick={() => onSelectTag(uuid)}>
        {name} {typeof qty !== "undefined" ? <>({qty})</> : null}
      </Button>
      {canBeRemoved && <Button icon="X" seoText="Удалить" className={cssTagDeleteButton} onClick={onClearTag && (() => onClearTag(uuid))} />}
    </div>;
};
export { TagControl };
import { AvailableViewDataType } from "./types"
import { colors } from "../../styles/utils/vars"

const enum AvailableStatus {
  MANY = "Много",
  ENOUGH = "Достаточно",
  FEW = "Мало",
  NOT = "Нет в наличии",
}

const AVAILABLE_VIEW_DATA: AvailableViewDataType = {
  [AvailableStatus.MANY]: {
    icon: "StatsMany",
    label: AvailableStatus.MANY,
    color: colors.brand.green,
  },
  [AvailableStatus.ENOUGH]: {
    icon: "StatsMany",
    label: AvailableStatus.ENOUGH,
    color: colors.brand.green,
  },
  [AvailableStatus.FEW]: {
    icon: "StatsFew",
    label: AvailableStatus.FEW,
    color: colors.brand.yellow,
  },
  [AvailableStatus.NOT]: {
    icon: "StatsNot",
    label: AvailableStatus.NOT,
    color: colors.red,
  },
}
export { AvailableStatus, AVAILABLE_VIEW_DATA }

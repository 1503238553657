import { cx } from "@linaria/core";
import Swiper, { FreeMode, Mousewheel, Navigation } from "swiper";
import { SwiperSlide } from "swiper/react";
import React, { FC, memo, useState } from "react";
import { PLACEMENT_ARROW, SwiperWrapper, VARIANT_ARROW } from "@/components/Swiper/SwiperWrapper";
import { StyledTagsSection } from "@/components/Tags/StyledTags";
import { TagControl } from "@/components/Tags/TagControl";
import { cssIsActive } from "@/styles/utils/Utils";
import { TagsListPropsType } from "./types";
import "swiper/css/free-mode";
const TagsSlider: FC<TagsListPropsType> = memo(({
  tags,
  selectedCategory,
  canBeRemoved,
  onSelectTag,
  onClearTag
}) => {
  const [swiperInstance, setSwiperInstance] = useState<null | Swiper>(null);
  return <StyledTagsSection>
        <SwiperWrapper onInit={setSwiperInstance} slidesPerView={"auto"} spaceBetween={10} modules={[FreeMode, Navigation, Mousewheel]} freeMode withNavigation variantArrow={VARIANT_ARROW.ANGLE} placementArrow={PLACEMENT_ARROW.HORIZONTAL} mousewheel={{
      sensitivity: 2
    }} slideToClickedSlide updateOnWindowResize watchSlidesProgress>
          {tags.map(({
        id,
        name,
        qty,
        selected = false,
        removable = false
      }, index) => {
        if (selectedCategory === id) {
          swiperInstance?.slideTo(index);
        }
        return <SwiperSlide key={id} virtualIndex={index}>
                  <TagControl uuid={id} name={name} qty={qty} canBeRemoved={canBeRemoved ?? removable} className={cx((selectedCategory === id || selected) && cssIsActive)} onSelectTag={onSelectTag} onClearTag={onClearTag} />
                </SwiperSlide>;
      })}
        </SwiperWrapper>
      </StyledTagsSection>;
});
TagsSlider.displayName = "TagsSlider";
export { TagsSlider };